<template>
  <KonfirmasiRakorPltuForm mode="Ubah" module="Data Konfirmasi Rakor PLTU"> </KonfirmasiRakorPltuForm>
</template>

<script>
import KonfirmasiRakorPltuForm from './form';

const KonfirmasiRakorPltuUpdate = {
  name: 'KonfirmasiRakorPltuUpdate',
  components: { KonfirmasiRakorPltuForm },
};

export default KonfirmasiRakorPltuUpdate;
</script>
